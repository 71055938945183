<template>
  <r-card>
    <h3 class="general-information-title">Salary Information</h3>
    <div class="columns is-multiline">
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="cash" size="is-small" />
          Base Salary
        </span>
        <p class="settings-personal-info-data">
          {{ baseSalary }}
        </p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="account-cash" size="is-small" />
          Probation Salary
          <b-tooltip
            position="is-right"
            multilined
            label="Probation Salary will not be calculated in grand total"
          >
            <b-icon icon="help-circle" size="is-small"></b-icon>
          </b-tooltip>
        </span>
        <p class="settings-personal-info-data">
          {{ probSalary }}
        </p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="cash-multiple" size="is-small" />
          Food Allowance
        </span>
        <p class="settings-personal-info-data">
          {{ foodAllowance }}
        </p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="cash-multiple" size="is-small" />
          Transport Allowance
        </span>
        <p class="settings-personal-info-data">
          {{ transportAllowance }}
        </p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="cash-multiple" size="is-small" />
          Position Allowance
        </span>
        <p class="settings-personal-info-data">
          {{ positionAllowance }}
        </p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="cash-multiple" size="is-small" />
          Additional Allowance
        </span>
        <p
          class="settings-personal-info-data"
          v-html="additionalAllowances"
        ></p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-grand-total has-text-weight-bold">
          <b-icon icon="cash-check" size="is-small" />
          Grand Total
        </span>
        <span class="settings-personal-info-data has-text-weight-bold">
          {{ grandTotal }}
        </span>
      </div>
    </div>
  </r-card>
</template>
<script>
export default {
  props: {
    baseSalary: String,
    probSalary: String,
    foodAllowance: String,
    transportAllowance: String,
    positionAllowance: String,
    additionalAllowances: String,
    grandTotal: String,
  },
}
</script>
