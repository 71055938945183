<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Employee Info</h2>
    </template>
    <template #page-content>
      <div id="personal-information" class="component-basic-info">
        <div v-if="!isLoading">
          <EmployeeInfoHeaderManager
            :user-id="currentUser.id"
            :profile-picture-url="formatProfilePictureUrl(currentUser)"
            :employee-id="formatEmployeeId(currentUser)"
            :full-name="currentUser.fullName"
            :position-name="formatPositionName(currentUser)"
            :working-status="currentUser.workingStatus"
            :timezone="formatTimezone(currentUser)"
          />

          <div class="columns is-multiline">
            <div class="column is-6-desktop is-6-tablet is-12-mobile">
              <GeneralInformation
                :user-id="currentUser.id"
                :birth-day="formatBirthday(currentUser)"
                :address="formatAddress(currentUser)"
                :gender="formatGender(currentUser)"
                :contact-number="formatPhoneNumber(currentUser)"
                :personal-email="formatPersonalEmail(currentUser)"
                :office-email="formatOfficeEmail(currentUser)"
                :religion="formatReligion(currentUser)"
                :blood-type="formatBloodType(currentUser)"
              />
            </div>
            <div class="column is-6-desktop is-6-tablet is-12-mobile">
              <ProfessionalInformation
                :department="formatDepartment(currentUser)"
                :role="formatRole(currentUser)"
                :joinDate="formatJoinDate(currentUser)"
                :contactNumber="formatPhoneNumber(currentUser)"
                :reportingToPic="formatReportingToPicture(currentUser)"
                :reportingToName="formatReportingToName(currentUser)"
                :reportingToPosition="formatReportingToPosition(currentUser)"
              ></ProfessionalInformation>

              <SalaryInformation
                :baseSalary="formatBaseSalary(currentUser)"
                :probSalary="formatProbSalary(currentUser)"
                :foodAllowance="formatEatAllowances(currentUser)"
                :transportAllowance="formatTransportAllowances(currentUser)"
                :positionAllowance="formatPositionAllowances(currentUser)"
                :additionalAllowances="formatAdditionalAllowances(currentUser)"
                :grandTotal="grandTotal(currentUser)"
              ></SalaryInformation>
            </div>
            <div class="column is-6-desktop is-6-tablet is-12-mobile"></div>
          </div>
        </div>
        <div v-else>
          <Loading :is-loading="isLoading" />
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import moment from 'moment'
import EmployeeInfoHeaderManager from '../../components/EmployeeInfoHeaderManager'
import GeneralInformation from '../../components/GeneralInformation'
import SalaryInformation from '../../components/SalaryInformation'
import ProfessionalInformation from '../../components/ProfessionalInformation'
import { formatCurrency } from '@/services/util'

export default {
  components: {
    GeneralInformation,
    EmployeeInfoHeaderManager,
    SalaryInformation,
    ProfessionalInformation,
    Loading,
  },
  data() {
    return {
      userId: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserDetailData',
      isLoading: 'user/getIsLoading',
    }),
  },
  methods: {
    ...mapActions({
      getUserDetail: 'user/getUserDetailById',
    }),
    // ----------------------------- Salary format -----------------------------
    formatCurrency(number) {
      return formatCurrency(number)
    },
    formatBaseSalary(user) {
      if (user?.employment?.baseSalary) {
        return this.formatCurrency(user?.employment?.baseSalary)
      }
      return '-'
    },
    formatProbSalary(user) {
      if (
        user?.employment?.probationSalary &&
        user?.employment?.probationSalary !== 0
      ) {
        return this.formatCurrency(user?.employment?.probationSalary)
      }
      return '-'
    },
    formatEatAllowances(user) {
      if (user?.employment?.eatAllowance !== 0) {
        return this.formatCurrency(user?.employment?.eatAllowance)
      }
      return '-'
    },
    formatTransportAllowances(user) {
      if (user?.employment?.transportAllowance !== 0) {
        return this.formatCurrency(user?.employment?.transportAllowance)
      }
      return '-'
    },
    formatPositionAllowances(user) {
      if (user?.employment?.positionalAllowance !== 0) {
        return this.formatCurrency(user?.employment?.positionalAllowance)
      }
      return '-'
    },
    formatAdditionalAllowances(user) {
      let additionalAllowances = ''
      for (let i = 0; i < user?.employment?.employmentAllowances.length; i++) {
        additionalAllowances +=
          '<div class="columns is-multiline">' +
          '<div class="settings-personal-info-all-icon column is-3">' +
          user?.employment?.employmentAllowances[i].allowanceSetting?.name +
          ': ' +
          '</div>' +
          '<div class="column is-9">' +
          this.formatCurrency(
            user?.employment?.employmentAllowances[i].allowanceAmount
          ) +
          '</div>' +
          '</div>'
      }
      return additionalAllowances
    },
    getAdditionalAllowancesTotal(user) {
      let value = user?.employment?.employmentAllowances
      const totalAdditionalAllowances = value?.reduce(
        (allowanceTotal, allowance) =>
          allowanceTotal + allowance.allowanceAmount,
        0
      )

      return totalAdditionalAllowances
    },
    grandTotal(user) {
      let total =
        user?.employment?.baseSalary +
        user?.employment?.eatAllowance +
        user?.employment?.transportAllowance +
        user?.employment?.positionalAllowance +
        this.getAdditionalAllowancesTotal(user)

      return this.formatCurrency(total)
    },
    // ------------------------- End of Salary format --------------------------
    formatPhoneNumber(user) {
      if (user?.personalPhone) {
        return user?.personalPhone
      }
      return '-'
    },
    formatPersonalEmail(user) {
      if (user?.personalEmail) {
        return user?.personalEmail
      }
      return '-'
    },
    formatOfficeEmail(user) {
      if (user?.workEmail) {
        return user?.workEmail
      }
      return '-'
    },
    formatBloodType(user) {
      if (user?.bloodType) {
        return user.bloodType.toUpperCase()
      }
      return '-'
    },
    formatReligion(user) {
      if (user?.religion) {
        return user.religion[0].toUpperCase() + user.religion.slice(1)
      }
      return '-'
    },

    formatPositionName(user) {
      if (user?.employment?.position) {
        return user.employment.position.positionName
      }
      return '-'
    },
    formatBirthday(user) {
      if (user?.placeOfBirth) {
        if (user?.dateOfBirth) {
          return user?.placeOfBirth + ', ' + this.parseDate(user?.dateOfBirth)
        }
        return user?.placeOfBirth
      }
      return '-'
    },
    formatDepartment(user) {
      if (user?.employment?.position?.department?.departmentName) {
        return user.employment.position.department.departmentName
      }
      return '-'
    },
    formatRole(user) {
      if (user?.employment?.position?.positionName) {
        return user.employment.position.positionName
      }
      return '-'
    },
    formatJoinDate(user) {
      if (user?.employment?.joinDate) {
        return moment(user.employment.joinDate).format('DD MMMM YYYY')
      }
      return '-'
    },
    formatReportingToPicture(user) {
      if (user?.reportingTo && user?.employment?.reportingTo?.gender) {
        return this.formatProfilePictureUrl(
          user.reportingTo,
          user.employment.reportingTo.gender
        )
      }
    },
    formatReportingToName(user) {
      if (user?.reportingTo) {
        return user.reportingTo.firstName + ' ' + user.reportingTo.lastName
      }
      return '-'
    },
    formatReportingToPosition(user) {
      if (user?.reportingTo) {
        return user.reportingTo.positionName
      }
      return '-'
    },

    parseDate(timeStamp) {
      return moment(timeStamp).format('DD MMMM YYYY')
    },
    parseDateTime(timeStamp) {
      return moment(timeStamp).format('DD MMM YYYY, hh:mm')
    },
    formatGender(user) {
      if (user?.gender == 'F') {
        return 'Female'
      }
      return 'Male'
    },
    formatProfilePictureUrl(user, gender) {
      if (user?.profilePictureUrl) {
        return user?.profilePictureUrl
      }

      if (user?.gender == 'F' || gender === 'F') {
        return '/images/default-profile-picture-female.png'
      }

      return '/images/default-profile-picture-male.png'
    },
    formatEmployeeId(user) {
      if (user?.employment?.nik) {
        return user?.employment?.nik
      }
      return '-'
    },
    formatAddress(user) {
      let address = '-'

      if (user?.address?.streetName) {
        address = user?.address?.streetName
      }
      if (user?.address?.district) {
        address += ', ' + user?.address?.district
      }
      if (user?.address?.village) {
        address += ', ' + user?.address?.village
      }
      if (user?.address?.city) {
        address += ', ' + user?.address?.city
      }
      if (user?.address?.province) {
        address += ', ' + user?.address?.province
      }
      return address
    },
    formatAnnualLeave(user) {
      let annualLeave = '-'
      let remaining = '-'
      if (user?.employment?.annualLeave) {
        annualLeave = user.employment.annualLeave
      }
      if (user?.employment?.remainingAnnualLeave) {
        remaining = user.employment.remainingAnnualLeave
      }
      return `${remaining}/${annualLeave}`
    },

    formatTimezone(user) {
      let timezone = '-'

      if (user?.timezone) {
        timezone = user.timezone
      }
      return timezone
    },

    async loadEmployeeGeneralList() {
      try {
        await this.getUserDetail(this.userId)
      } catch (err) {
        console.log(err)
      }
    },
  },
  async created() {
    this.userId = this.$router.currentRoute.params.id
    await this.loadEmployeeGeneralList(this.userId)
  },
}
</script>
