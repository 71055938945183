<template>
  <div id="personal-info-profile-picture">
    <div class="profile-picture-container">
      <img
        :src="profilePictureUrl"
        alt="Profile Picture"
        class="personal-info-profile-picture"
      />

      <div class="btn-container-edit-picture" v-if="uploadImageProgress === 0">
        <b-icon
          class="btn-change-profile-picture"
          icon="camera"
          @click.native="openModalChangePicture"
        ></b-icon>
      </div>
    </div>

    <!-- Modal to create new request -->
    <r-page-modal
      :prompt-on-leave="false"
      prompt-message="Are You sure?"
      v-if="isModalChangePictureOpen"
      @hide="closeModalChangeProfilePicture"
      :is-modal="true"
      class="modal-change-profile-picture"
    >
      <template #page-content>
        <h3 class="modal-title has-text-weight-black">
          Change your profile picture
        </h3>

        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="has-text-centered">
              <img
                :src="imageToBeReplaced"
                alt="Profile Picture"
                class="personal-info-profile-picture"
              />
            </div>

            <div>
              <b-progress
                :value="uploadImageProgress"
                v-if="uploadImageProgress > 0"
                show-value
                format="percent"
                type="is-danger"
                class="has-text-white"
              >
                Uploading image ... ( {{ uploadImageProgress }}% )
              </b-progress>
            </div>
          </div>
          <div class="column is-12">
            <p>Upload Picture</p>
            <b-upload
              v-model="file"
              accept="image/png, image/jpeg"
              drag-drop
              expanded
              @input="getImage"
              :disabled="uploadImageProgress > 0"
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="image" size="is-large"></b-icon>
                  </p>
                  <p>
                    Drag image here or
                    <span class="has-red-text">Browse</span>
                  </p>
                </div>
              </section>
            </b-upload>
          </div>
          <div class="column is-12" v-if="file != null">
            <div class="tag is-primary">
              <span class="file-tag">
                {{ file.name }}
              </span>

              <button
                class="delete is-small"
                type="button"
                @click="removePickedImage()"
              ></button>
            </div>
          </div>
          <div class="column is-12">
            <div class="columns">
              <div class="column is-6">
                <b-button
                  @click="closeModalChangeProfilePicture"
                  type="is-grey"
                  expanded
                >
                  Back
                </b-button>
              </div>
              <div class="column is-6">
                <b-button
                  @click="uploadProfileImage"
                  expanded
                  :disabled="uploadImageProgress > 0"
                >
                  Upload
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </r-page-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, } from 'vuex'
import { showToast } from '../../../services/util'

export default {
  props: {
    userId : Number,
    profilePictureUrl: String,
  },
  computed: {
    ...mapGetters({
      uploadImageProgress: 'user/getUploadImageProgress',
    }),
  },
  data() {
    return {
      isModalChangePictureOpen: false,
      imageToBeReplaced: null,
      file: null,
    }
  },
  methods: {
    ...mapActions({
      changeProfileImage: 'user/changeProfileImage',
    }),

    async uploadProfileImage() {
      if (this.file == null) {
        showToast('Please pick an image', 'is-danger', 'is-bottom')
        return
      }
      try {
        let form = new FormData()
        form.append('user_id', this.userId)
        form.append('image', this.file)
        await this.changeProfileImage(form)
      } catch (err) {
        console.log(err)
        showToast(err.response.data.message, 'is-danger', 'is-bottom')
      }
      this.closeModalChangeProfilePicture()
      this.$emit('on-upload-finished')
    },
    openModalChangePicture() {
      this.imageToBeReplaced = this.profilePictureUrl
      this.isModalChangePictureOpen = true
    },
    closeModalChangeProfilePicture() {
      this.file = null
      this.isModalChangePictureOpen = false
    },
    removePickedImage() {
      // reset to original image
      this.imageToBeReplaced = this.profilePictureUrl
      this.file = null
    },

    getImage(e) {
      this.file = e

      //*validate size here. Size is in Byte. maximum 2MB
      if (this.file.size > 2048000) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'File size maximum is 2 MB',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      } else {
        let reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = (e) => {
          this.imageToBeReplaced = e.target.result
        }
      }
    },

  },
}
</script>

<style scoped lang="scss">
.profile-picture-container {
  position: relative;
  width: 118px;
  height: 118px;
}

.personal-info-profile-picture {
  border-radius: 10px;
  width: 118px;
  height: 118px;
  object-fit: cover;
  flex: 1;
}

.btn-container-edit-picture {
  position: absolute;
  top: 40%;
  color: white;
  text-align: center;
  width: 100%;
}

.btn-change-profile-picture {
  min-width: 30px;
  width: 30px;
}

.btn-container-edit-picture {
  opacity: 0;
}


.profile-picture-container:hover {
  cursor: pointer;

  .personal-info-profile-picture {
    opacity: 0.5;
    transition: 0.5s;
  }


  .btn-container-edit-picture {
    opacity: 1;
    transition: 0.5s;
  }
}
</style>
