<template>
  <div class="r-card" id="general-information-card">
    <div class="level mb-0">
      <div class="level-left">
        <h3>General Information</h3>
      </div>
      <div class="level-right" v-if="editable">
        <b-button
          class="is-command"
          id="general-info-edit-btn"
          iconLeft="pencil"
          @click="$router.push('/settings/personal-info/edit')"
        >
          <p>Edit</p>
        </b-button>
      </div>
    </div>

    <div class="columns is-multiline mb-0">
      <div class="column is-12">
        <span>
          <b-icon icon="cake-variant" size="is-small" />
          Birthday
        </span>
        <p class="settings-personal-info-data">
          {{ birthDay }}
        </p>
      </div>

      <div class="column is-12">
        <span>
          <b-icon icon="home" size="is-small" />
          Address
        </span>
        <p class="settings-personal-info-data">
          {{ address }}
        </p>
      </div>

      <div class="column is-12">
        <span>
          <b-icon icon="gender-male-female" size="is-small" />
          Gender
        </span>
        <p class="settings-personal-info-data">
          {{ gender }}
        </p>
      </div>

      <div class="column is-12">
        <span>
          <b-icon icon="phone" size="is-small" />
          Contact Number
        </span>
        <p class="settings-personal-info-data">
          {{ contactNumber }}
        </p>
      </div>
    </div>
    <div class="columns is-multiline mt-1">
      <div class="column is-6">
        <span>
          <b-icon icon="email" size="is-small" />
          Email
        </span>
        <p class="settings-personal-info-data">
          {{ personalEmail }}
        </p>
      </div>
      <div class="column is-6">
        <span>
          <b-icon icon="email" size="is-small" />
          Office Email
        </span>
        <p class="settings-personal-info-data">
          {{ officeEmail }}
        </p>
      </div>
      <div class="column is-6">
        <span>
          <b-icon icon="book-open-blank-variant" size="is-small" />
          Religion
        </span>
        <p class="settings-personal-info-data">
          {{ religion }}
        </p>
      </div>
      <div class="column is-6">
        <span>
          <b-icon icon="water" size="is-small" />
          Blood Type
        </span>
        <p class="settings-personal-info-data">
          {{ bloodType }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
    })
  },
  props: {
    userId : Number,
    birthDay: String,
    address: String,
    gender: String,
    contactNumber: String,
    personalEmail: String,
    officeEmail: String,
    religion: String,
    bloodType: String,
    editable : Boolean
  },
}
</script>

<style scoped></style>
