<template>
  <r-card>
    <div class="level mb-0">
      <div class="level-left">
        <h3 class="general-information-title">Professional Information</h3>
      </div>
    </div>

    <div class="columns is-multiline mb-0">
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="checkbox-blank-circle-outline" size="is-small" />
          Department
        </span>
        <p class="settings-personal-info-data">
          {{ department }}
        </p>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="flash-circle" size="is-small" />
          Role
        </span>
        <p class="settings-personal-info-data">
          {{ role }}
        </p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="clock-time-four-outline" size="is-small" />
          Join Date
        </span>
        <p class="settings-personal-info-data">
          {{ joinDate }}
        </p>
      </div>

      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="phone" size="is-small" />
          Contact Number
        </span>
        <p class="settings-personal-info-data">
          {{ contactNumber }}
        </p>
      </div>
      <div class="column is-6">
        <span class="settings-personal-info-all-icon">
          <b-icon icon="account-multiple" size="is-small" />
          Report to
        </span>
        <div class="is-flex">
          <img
            :src="reportingToPic"
            alt="Profile Picture"
            class="professional-report-profile-picture"
          />
          <div>
            <p class="professional-report-name">
              {{ reportingToName }}
            </p>
            <p class="professional-report-position">
              {{ reportingToPosition }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </r-card>
</template>
<script>
export default {
  props: {
    department: String,
    role: String,
    joinDate: String,
    contactNumber: String,
    reportingToPic: String,
    reportingToName: String,
    reportingToPosition: String,
  },
}
</script>
